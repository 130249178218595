import React, { useState, useEffect, useRef, createElement } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './logo.svg';
import copy from './images/svg/copy-v2.svg';
import close from './images/svg/close.svg';
import download from './images/svg/download.svg';
import search from './images/svg/search.svg';
import './App.scss';
import TemplateOne from './Components/TemplateOne/TemplateOne';
import Loader from "./Components/component_helpers/loader.js";
import Login from './Components/Login/Login';
import Notify from 'simple-notify';
import 'simple-notify/dist/simple-notify.min.css';
require('dotenv').config();

function pushNotify(heading, message) {
  new Notify({
    status: 'success',
    title: heading,
    text: message,
    effect: 'slide',
    speed: 300,
    customClass: 'notification-sig',
    customIcon: null,
    showIcon: true,
    showCloseButton: true,
    autoclose: true,
    autotimeout: 4000,
    gap: 20,
    distance: 20,
    type: 1,
    position: 'right bottom',
  });
}

function App() {
  const [clickNavOne, setClickNavOne] = useState(false);
  const [clickNavTwo, setClickNavTwo] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyContentSuccess, setCopyContentSuccess] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token') || "");
  const [firstName, setFirstName] = useState();
  const [signatures, setSignatures] = useState();
  const [company, setCompany] = useState();
  const [userData, setUserData] = useState({
    first_Name: '',
    last_name: '',
    email: '',
    company: '',
  });
  const [signatureSavedData, setSignatureSavedData] = useState([]);
  // saved signature data
  const [heading, setHeading] = useState();
  const [headingColor, setHeadingColor] = useState('');
  const [fontSaved, setFontSaved] = useState('');
  // additional bylines
  const [byline, setBylineInit] = useState('');
  const [bylineColor, setBylineColorChange] = useState('');
  const [addByline, setAddByline] = useState([]);
  const [bylineCount, setBylineCount] = useState();
  const [bylineAdd, setByline] = useState('');
  const [bylimeLimit, setBylineLimit] = useState(false);
  const [addColor, setAddColor] = useState('');
  // logo
  const [logo, setLogo] = useState('');
  const [logoWidth, setWidthLogo] = useState('');

  const [values, setValues] = useState();

  const savedSigRef = useRef(null);

  // loader
  const [loaderActive, setLoaderActive] = useState(false);


  useEffect(() => {
    // check for token in storage
    
    if (token) {
    

      fetch(`/api/record/`)
        .then((response) => response.json())
        .then((data) => {
          data.find(function (info, index) {
            if (info._id == token) {
              setUserData({
                first_name: info.first_name,
                last_name: info.last_name,
                company: info.company,
                email: info.email,
              });

              setSignatureSavedData(info.signature_templates);
              // generate styles
              setHeadingColor(info.signature_style['headingColor']);
              setBylineColorChange(info.signature_style['bylineColor']);
              setBylineCount(info.signature_style['bylineCount']);
              setAddColor(info.signature_style['addColor']);
              setLogo(info.signature_style['logo']);
              setWidthLogo(info.signature_style['logoWidth']);
              setValues(info.signature_style['extra']);
              setFontSaved(info.signature_style['font']);
              // turn off loader
              setLoaderActive(true)
            }
          });
        })
        .catch((error) => {
          window.alert('error!');
          return;
        });
    }
  }, [token]);
  if (!token) {
    return (
      <div>
        <Login setToken={setToken} setFirstName={setFirstName} setSignatures={setSignatures} setCompany={setCompany} />
      </div>
    );
  }

  function copyToClipboard(text) {
    var dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  }
  function deleteSignature(e) {
    e.preventDefault();
    window.confirm("Are you sure you'd like to delete this signature?");
    e.currentTarget.parentElement.style.display = 'none';
    pushNotify('Signature deleted!', 'You have removed a saved signature.');
    const file_name = e.currentTarget.parentElement.getAttribute('data-signature-name');
    const signature = e.currentTarget.parentElement.getAttribute('data-signature');
    let newArray = [];
    signatureSavedData.map((obj, i) => {
      if (obj[0]['saveSignatureName'] === file_name && obj[0]['table'] === signature) {
        // console.log(obj)
        // nothing
      } else {
        newArray.push(obj);
      }
    });

    let newValues = {
      $set: {
        signature_templates: newArray,
      },
    };
    fetch(`/api/remove/${token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newValues),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        console.log(data);
        console.log(newValues);
      });
  }
  function copySig(e) {
    const placeholder = document.createElement('div');
    placeholder.innerHTML = e.currentTarget.parentNode.getAttribute('data-signature');
    let table = placeholder.firstChild;
    document.body.appendChild(placeholder);
    let range = document.createRange();

    range.selectNode(table);
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    window.getSelection().removeAllRanges(); // to deselect
    pushNotify('Signature copied!', 'your signature has been copied to the clipboard');
    document.body.removeChild(placeholder);
  }

  function downloadSig(e) {
    const blob = new Blob([e.currentTarget.parentNode.getAttribute('data-signature')], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = e.currentTarget.parentNode.getAttribute('data-signature-name');
    a.click();
    URL.revokeObjectURL(url);
    pushNotify('Signature Downloaded!', 'Your signature has been downloaded');
  }

  function filterSaved(e) {
    const value = e.currentTarget.value;
    const children = savedSigRef.current.children;

    for (let i = 0; i < children.length; i++) {
      const child_name = children[i].getAttribute('data-signature-name').toLowerCase();
      if (child_name.match(value.toLowerCase())) {
        console.log('child:');
        children[i].style.display = 'flex';
      } else {
        children[i].style.display = 'none';
      }
    }
  }

  function displaySaved(e) {
    const saved_heading = e.currentTarget.parentElement.getAttribute('data-signature-main-heading');
    const saved_heading_color = e.currentTarget.parentElement.getAttribute('data-signature-main-heading-color');
    const saved_byline = e.currentTarget.parentElement.getAttribute('data-signature-byline');
    const saved_byline_color = e.currentTarget.parentElement.getAttribute('data-signature-byline-color');
    // additional byline
    const saved_additional_byline = e.currentTarget.parentElement.getAttribute('data-signature-add-byline');
    setHeading(saved_heading);
    setHeadingColor(saved_heading_color);
    setBylineInit(saved_byline);
    setBylineColorChange(saved_byline_color);
    if (saved_additional_byline != '') {
      setAddByline(true);
    }
  }

  const navClickOne = (event) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setClickNavOne(true);
    setClickNavTwo(false);
  };

  const navClickTwo = (event) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    setClickNavTwo(true);
    setClickNavOne(false);
  };

  const logOut = (event) => {
    event.preventDefault();
    localStorage.removeItem('token');
    window.location.reload();
  };

  return (
    <div className="app-wrap">
    <Loader
    isActive={loaderActive ? 'loader-is-active' : 'loader-not-active'}
    />
    
      <Router>
        <div className="navigation">
          <header>
            <h5 className="mini-heading">Signature Builder</h5>
            
            {userData.first_name ? <h1 style={{ textTransform: 'capitalize' }}>Welcome {userData.first_name}!</h1> : ''}
          </header>
          <section>
            <h5 className="mini-heading">Instructions</h5>
            <ul>
              <li>Fill in the form to create an email signature.</li>
              <li>
                Copy the signature{' '}
                <img src={copy} style={{ height: '20px', position: 'relative', top: '2.5px', marginRight: '15px' }} />
              </li>
              <li>
                Download the signatures{' '}
                <img src={download} style={{ height: '20px', position: 'relative', top: '2.5px', marginRight: '15px' }} />
              </li>
              <li>
                Click the{' '}
                <strong>
                  <em>`Save`</em>
                </strong>{' '}
                button to save your signature.
              </li>
            </ul>
          </section>
          <section>
            <button className="add-another" onClick={logOut}>
              Logout
            </button>
          </section>
        </div>

        <Routes>
          <Route
            exact
            path="/"
            element={
              <TemplateOne
                setCompany={company ? company : userData.company}
                setSavedFont={fontSaved}
                setSavedHeading={heading}
                setSavedHeadingColor={headingColor}
                setBylineInit={byline}
                setBylineColorChange={bylineColor}
                setAddByline={addByline}
                setSavedAddBylineColor={addColor}
                setSavedBylineCount={bylineCount}
                setSavedExtra={values}
                setSavedLogo={logo}
                setLogoWidth={logoWidth}
                setExtraValueHeadingColor={
                  values
                    ? values.map((obj, i) => {
                        return obj.headingColor;
                      })
                    : ''
                }
                setExtraValueHeadingTwoColor={
                  values
                    ? values.map((obj, i) => {
                        return obj.extraColor;
                      })
                    : ''
                }
              />
            }
          />
        </Routes>
        <div className="link-wrapper navigation">
          <section className="links" style={{ marginTop: '0' }}>
            <h5 className="mini-heading">Saved Signatures</h5>
            <h2 style={{ marginBottom: '25px' }}>Your Signatures</h2>
            <div className="search-wrapper">
              <input type="search" onChange={filterSaved} placeholder="Filter signatures.." />
              <img src={search} className="icon" />
            </div>
            <h5 className="mini-heading">x{signatureSavedData.length} Results</h5>

            <div className="saved-signatures-wrapper" ref={savedSigRef}>
              {signatureSavedData != undefined ? (
                signatureSavedData.map((obj, i) => {
                  return (
                    <button
                      className="add-another saved-signature-button"
                      data-signature={obj[0]['table']}
                      data-signature-name={obj[0]['saveSignatureName']}
                      data-signature-main-heading={obj[0]['heading']}
                      data-signature-main-heading-color={obj[0]['headingColor']}
                      data-signature-byline={obj[0]['byline']}
                      data-signature-byline-color={obj[0]['bylineColor']}
                      data-signature-add-byline={obj[0]['addByline']}
                      data-signature-byline-count={obj[0]['bylineCount']}
                      data-signature-byline-add={obj[0]['bylineAdd']}
                      data-signature-byline-add-color={obj[0]['addColor']}
                      data-signature-logo={obj[0]['logo']}
                      data-signature-logo-width={obj[0]['logoWidth']}
                      data-signature-extra={JSON.stringify(obj[0]['extra'])}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <span> {obj[0]['saveSignatureName']} </span>
                      <img
                        src={download}
                        alt={'download'}
                        style={{ height: '20px', marginLeft: '15px' }}
                        onClick={downloadSig}
                      />
                      <img src={copy} alt={'copy'} style={{ height: '20px', marginLeft: '10px' }} onClick={copySig} />
                      <img src={close} alt={'delete'} style={{ height: '20px', marginLeft: '5px' }} onClick={deleteSignature} />
                    </button>
                  );
                })
              ) : (
                <div>
                  <ul>
                    <li>No signatures..</li>
                  </ul>
                </div>
              )}
            </div>
          </section>
        </div>
      </Router>
  
    </div>
  );
}

export default App;
