/*

Template One

*/

import React, { useEffect, useState, useRef } from "react";
import FormFields from "../component_helpers/form_fields.js";
import FileUpload from "../component_helpers/FileUpload.js";
import Close from "../../images/svg/close.svg";
import copy from "../../images/svg/copy-v2.svg";
import download from "../../images/svg/download.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PropTypes from "prop-types";
import Notify from "simple-notify";
import "simple-notify/dist/simple-notify.min.css";

function pushNotify(heading, message) {
  new Notify({
    status: "success",
    title: heading,
    text: message,
    effect: "slide",
    speed: 300,
    customClass: "notification-sig",
    customIcon: null,
    showIcon: true,
    showCloseButton: true,
    autoclose: true,
    autotimeout: 7000,
    gap: 20,
    distance: 20,
    type: 1,
    position: "right bottom",
  });
}

function TemplateOne(props) {
  // user info
  const [token, setToken] = useState("");
  const [userData, setUserData] = useState({
    id: "",
    first_name: "",
    last_name: "",
    email: "",
    company: "",
  });
  // refs
  const [template, setTemplate] = useState("");
  const [font, setFont] = useState("");
  const [heading, setHeading] = useState("");
  const [headingColor, setHeadingColor] = useState("");
  // bylines
  const [byline, setBylineInit] = useState("");
  const [bylineColor, setBylineColorChange] = useState("");
  // additional bylines
  const [addByline, setAddByline] = useState([]);
  const [bylineCount, setBylineCount] = useState();
  const [bylineAdd, setByline] = useState("");
  const [bylimeLimit, setBylineLimit] = useState(false);
  const [addColor, setAddColor] = useState("");
  // logo
  const [logo, setLogo] = useState("");
  const [logoWidth, setWidthLogo] = useState("");

  // extras
  const [extraHeading, setExtraHeading] = useState("");
  const [extraHeadingColor, setExtraHeadingColor] = useState("");
  const [extraChange, setExtraChange] = useState("");
  const [extraChangeColor, setExtraChangeColor] = useState("");

  // dynamic extra
  const [dymExtraHeading, dymSetExtraHeading] = useState("");
  // dynamic extras
  const [values, setValues] = useState([
    { heading: "", headingColor: "", extra: "", extraColor: "" },
  ]);
  // copy
  const [copySuccess, setCopySuccess] = useState(false);
  const [copyContentSuccess, setCopyContentSuccess] = useState(false);
  const [copySaved, setCopySaved] = useState(false);
  const sigRef = useRef(null);
  const tableRef = useRef(null);

  const [referral, setReferral] = useState(false);

  const [extra_heading_color_one, set_extra_heading_color_one] = useState("");
  const [extra_value_color_one, set_extra_value_color_one] = useState("");
  const [extra_heading_color_two, set_extra_heading_color_two] = useState("");

  // show modal
  const [showModal, setShowModal] = useState(false);
  // saved signature name
  const [saveSignatureName, setSaveSignatureName] = useState("");

  const [signatureSaved, setSignatureSaved] = useState([
    {
      heading: "",
      headingColor: "",
      byline: "",
      bylineColor: "",
      addByline: "",
      bylineCount: "",
      bylineAdd: "",
      addColor: "",
      logo: "",
      logoWidth: "",
      extra: "",
    },
  ]);

  const [signatureSavedStyle, setSignatureSavedStyle] = useState([]);
  const [signatureSavedData, setSignatureSavedData] = useState([]);

  const signatureNameToSave = useRef(null);

  useEffect(() => {
    // Update the document title using the browser API
    // doc vars
    window.scrollTo({ top: 0, behavior: "smooth" });

    // token
    let token = localStorage.getItem("token");
    fetch(`/api/record/${token}`)
      .then((response) => response.json())
      .then((data) => {
        setToken(token);
      })
      .catch((error) => {
        console.log(error);
        return;
      });

    //set refs
  }, []);

  function copyToClipboard(text) {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  function downloadSig(e) {
    setCopySuccess(true);
    setCopyContentSuccess(false);
    copyToClipboard(sigRef.current.innerHTML);
    const blob = new Blob([sigRef.current.innerHTML], { type: "text/html" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "email-signature";
    a.click();
    URL.revokeObjectURL(url);
    pushNotify("Signature Downloaded!", "Your signature has been downloaded");
  }

  function modalClose() {
    setShowModal(false);
    setCopySaved(false);
  }

  function saveSig(e) {
    e.preventDefault();
    setCopySaved(true);
    let token = localStorage.getItem("token");
    setShowModal(true);
    let g;
    fetch(`/api/record/`)
      .then((response) => response.json())
      .then((data) => {
        data.find(function (info, index) {
          if (info._id == token) {
            setSignatureSavedData(info.signature_style);
          }
        });
      })
      .catch((error) => {
        window.alert("error!");
        return;
      });

    let obj = {};

    if (headingColor != "") {
      obj.headingColor = headingColor;
    }
    if (font != "") {
      obj.font = font;
    }
    if (bylineColor != "") {
      obj.bylineColor = bylineColor;
    }
    if (bylineCount != null) {
      obj.bylineCount = bylineCount;
    }
    if (addColor != "") {
      obj.addColor = addColor;
    }
    if (logo != "") {
      obj.logo = logo;
    }
    if (logoWidth != "") {
      obj.logoWidth = logoWidth;
    }

    setSignatureSavedStyle([obj]);
  }

  function signatureName(e) {
    const sig_name = e.currentTarget.value;

    const table =
      "<table style='font-family: Arial, sans-serif; text-align: left; color: rgb(0, 0, 0); font-size: 14px; line-height: 16px; width: 500px;'>" +
      tableRef.current.innerHTML +
      "</table>";
    setSignatureSaved([
      {
        table: table,
        saveSignatureName: sig_name,
      },
      {
        heading: heading,
        headingColor: headingColor,
        byline: byline,
        bylineColor: bylineColor,
        addByline: bylineAdd,
        addColor: addColor,
        logo: logo,
        logoWidth: logoWidth,
        extra: values,
      },
    ]);
  }

  function saveSignatureStyles(e) {
    e.preventDefault();
    let token = localStorage.getItem("token");

    const obj2 = signatureSavedData;
    const obj1 = signatureSavedStyle[0];

    for (const key in obj1) if (key in obj2) obj2[key] = obj1[key];
    console.log(obj2);
    const init_extra = values[0];
    const extra = obj2["extra"][0];
    console.log('init_entry' + init_extra);
    if(extra){
      for (let [key, value] of Object.entries(init_extra)) {
      if (value != "") {
        extra[key] = value;
      }
    }
    }
    

    let newValues = {
      $set: {
        signature_style: obj2,
      },
    };

    fetch(`/api/update-style/${token}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newValues),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        console.log(newValues);
        pushNotify("Styles saved", "your signature styling have been saved");
        setShowModal(false);
        setCopySaved(false);
      });
  }

  function saveSignatureWithName(e) {
    e.preventDefault();
    let token = localStorage.getItem("token");
    // root object to post
    let newValues = {
      $push: {
        signature_templates: signatureSaved,
      },
    };

    // create object based on changed values

    fetch(`/api/update/${token}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newValues),
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        window.location.reload();
      });
  }

  const copyContent = (e) => {
    var range = document.createRange();
    range.selectNode(tableRef.current);
    window.getSelection().removeAllRanges(); // clear current selection
    window.getSelection().addRange(range); // to select text
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    window.getSelection().removeAllRanges(); // to deselect
    pushNotify(
      "Signature copied!",
      "your signature has been copied to the clipboard"
    );
  };

  return (
    <div className="signature-parent-wrapper">
      <div className="signature-wrapper">
        {}
        <FormFields
          setBylineCount={setBylineCount}
          setFont={setFont}
          setSavedFont={props.setSavedFont}
          setSavedBylineCount={props.setSavedBylineCount}
          setSavedAddColor={props.setSavedAddBylineColor}
          setCompany={props.setCompany}
          setHeading={setHeading}
          setSavedHeading={props.setSavedHeading}
          setTemplate={setTemplate}
          setHeadingColor={setHeadingColor}
          setSavedHeadingColor={props.setSavedHeadingColor}
          setBylineInit={setBylineInit}
          setSavedBylineInit={props.setBylineInit}
          setBylineColorChange={setBylineColorChange}
          setSavedBylineColorChange={props.setBylineColorChange}
          setByline={setByline}
          setAddColor={setAddColor}
          setLogo={setLogo}
          setWidthLogo={setWidthLogo}
          setExtraHeading={setExtraHeading}
          setExtraHeadingColor={setExtraHeadingColor}
          setExtraHeadingColorSaved={props.setExtraValueHeadingColor}
          setExtraValueHeadingTwoColor={props.setExtraValueHeadingTwoColor}
          setExtraChange={setExtraChange}
          setExtraChangeColor={setExtraChangeColor}
          setExtraChangeColorSaved={""}
          dymSetExtraHeading={dymSetExtraHeading}
          setValues={setValues}
          setExtraSaved={props.setSavedExtra}
          heading="Template One"
        />
        {template == "template-two" ? (
          <span className="copy" ref={sigRef}>
            <table
              width={450}
              ref={tableRef}
              style={{
                fontFamily: font || props.setSavedFont,
                textAlign: "left",
                color: "rgb(0, 0, 0)",
                fontSize: "14px",
                lineHeight: "16px",
                width: "450px",
              }}
            >
              <tbody>
                <tr>
                  <td
                    rowSpan={7}
                    align="center"
                    style={{ width: "30%", paddingRight: "5px" }}
                  >
                    {logo || props.setSavedLogo ? (
                      <img
                        src={logo || props.setSavedLogo}
                        width={
                          logoWidth || props.setLogoWidth
                            ? logoWidth || props.setLogoWidth
                            : "130"
                        }
                        alt=""
                        style={{ maxWidth: "190px", paddingTop: "10px" }}
                      />
                    ) : (
                      ""
                    )}
                  </td>
                  <td
                    colSpan={5}
                    style={{
                      paddingTop: "10px",
                      paddingLeft: "15px",
                      fontSize: "16px",
                      fontWeight: 600,
                      color: headingColor || props.setSavedHeadingColor,
                    }}
                  >
                    {heading ? heading : ""}
                  </td>
                </tr>
                {addByline ? (
                  <tr>
                    <td
                      colSpan={5}
                      style={{
                        paddingLeft: "15px",
                        fontSize: "10px",
                        color: addColor || props.setSavedAddBylineColor,
                      }}
                    >
                      {bylineAdd}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {byline ? (
                  <tr>
                    <td
                      colSpan={5}
                      style={{
                        paddingLeft: "15px",
                        fontSize: "13px",
                        color: bylineColor || props.setBylineColorChange,
                      }}
                    >
                      {byline}
                      <br />
                      <br />
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {values.map((obj, i) => {
                  if (obj.heading === "P:" || obj.heading === "M:") {
                    return (
                      <tr>
                        <td colSpan={5} style={{ paddingLeft: "15px" }}>
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: "12px",
                              color:
                                obj.headingColor ||
                                props.setExtraValueHeadingColor,
                            }}
                          >
                            {obj.heading}
                          </span>
                          <span
                            style={{
                              fontSize: "12px",
                              color:
                                obj.extraColor ||
                                props.setExtraValueHeadingTwoColor,
                            }}
                          >
                            {" "}
                            {obj.extra}
                          </span>
                        </td>
                      </tr>
                    );
                  } else if (obj.heading == "W:") {
                    return (
                      <tr>
                        <td
                          colSpan={2}
                          style={{
                            paddingLeft: "15px",
                            color: "rgb(90, 90, 90)",
                          }}
                        >
                          <a
                            href={obj.extra}
                            style={{
                              color:
                                obj.headingColor ||
                                props.setExtraValueHeadingColor,
                              fontSize: "13px",
                              fontWeight: 600,
                              textDecoration: "none",
                            }}
                          >
                            {obj.extra}
                            <br />
                          </a>
                        </td>
                      </tr>
                    );
                  } else if (obj.heading == "E:") {
                    return (
                      <tr>
                        <td colSpan={5} style={{ paddingLeft: "15px" }}>
                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: "12px",
                              color:
                                obj.headingColor ||
                                props.setExtraValueHeadingColor,
                            }}
                          >
                            {obj.heading}
                          </span>
                          <span style={{ fontSize: "12px" }}>
                            <a
                              href={"mailto:" + obj.extra}
                              style={{
                                color:
                                  obj.extraColor ||
                                  props.setExtraValueHeadingTwoColor,
                                textDecoration: "none",
                              }}
                            >
                              {" "}
                              {obj.extra}
                            </a>
                          </span>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </span>
        ) : (
          <span className="copy" ref={sigRef}>
            <table
              width={500}
              ref={tableRef}
              style={{
                fontFamily: font || props.setSavedFont,
                textAlign: "left",
                color: "rgb(0, 0, 0)",
                fontSize: "14px",
                lineHeight: "16px",
                width: "500px",
              }}
            >
              {" "}
              <tbody>
                <tr>
                  <td
                    colSpan={5}
                    style={{
                      paddingTop: "10px",
                      fontSize: "20px",
                      fontWeight: 600,
                      color: headingColor || props.setSavedHeadingColor,
                      fontFamily: font || props.setSavedFont,
                    }}
                  >
                    {heading || props.setSavedHeading
                      ? props.setSavedHeading || heading
                      : ""}
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td
                    colSpan={5}
                    style={{
                      fontSize: "10px",
                      color: addColor || props.setSavedAddBylineColor,
                      fontFamily: font || props.setSavedFont,
                    }}
                    id="byline-color-2"
                  >
                    {bylineAdd}
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td
                    colSpan={5}
                    style={{
                      fontSize: "12px",
                      color: bylineColor || props.setBylineColorChange,
                      fontFamily: font || props.setSavedFont,
                    }}
                  >
                    {byline}
                    <br />
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td colSpan={5}>
                    {" "}
                    <br /> <br />
                    <img
                      src={logo || props.setSavedLogo}
                      id="logo-url"
                      width={logoWidth || props.setLogoWidth}
                      style={{ maxWidth: "251px" }}
                    />{" "}
                    <br />{" "}
                  </td>
                </tr>
                <tr>
                  {" "}
                  <td
                    colSpan={2}
                    style={{
                      color: "rgb(0,0,0)",
                      fontFamily: font || props.setSavedFont,
                    }}
                  >
                    {values.map((obj, i) => {
                      if (obj.heading === "P:" || obj.heading === "M:") {
                        return (
                          <span
                            style={{
                              fontFamily: font || props.setSavedFont,
                              fontSize: "13px",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "13px",
                                color:
                                  obj.headingColor ||
                                  props.setExtraValueHeadingColor,
                                fontFamily: font || props.setSavedFont,
                              }}
                            >
                              {obj.heading}{" "}
                            </span>
                            <span
                              style={{
                                color:
                                  obj.extraColor ||
                                  props.setExtraValueHeadingTwoColor,
                              }}
                            >
                              {obj.extra}
                            </span>{" "}
                            |{" "}
                          </span>
                        );
                      } else if (obj.heading == "W:") {
                        return (
                          <span style={{ fontFamily: font || props.setSavedFont }}>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "13px",
                                color:
                                  obj.headingColor ||
                                  props.setExtraValueHeadingColor,
                                fontFamily: font || props.setSavedFont,
                              }}
                            >
                              {obj.heading}{" "}
                            </span>
                            <a
                              href={obj.extra}
                              target="_blank"
                              style={{
                                color:
                                  obj.extraColor ||
                                  props.setExtraValueHeadingTwoColor,
                                fontSize: "13px",
                                fontWeight: "normal",
                                textDecoration: "none",
                                fontFamily: font || props.setSavedFont,
                                textDecoration: "none",
                              }}
                            >
                              {obj.extra} |{" "}
                            </a>
                          </span>
                        );
                      } else if (obj.heading == "E:") {
                        return (
                          <span>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "13px",
                                color:
                                  obj.headingColor ||
                                  props.setExtraValueHeadingColor,
                                fontFamily: font || props.setSavedFont,
                                textDecoration: "none",
                              }}
                            >
                              {obj.heading}{" "}
                            </span>
                            <a
                              href={"mailto:" + obj.extra}
                              style={{
                                color:
                                  obj.extraColor ||
                                  props.setExtraValueHeadingTwoColor,
                                fontSize: "13px",
                                fontWeight: "normal",
                                textDecoration: "none",
                                fontFamily: font || props.setSavedFont,
                                textDecoration: "none",
                              }}
                            >
                              {obj.extra} |{" "}
                            </a>
                          </span>
                        );
                      }
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </span>
        )}
      </div>
      <div className="download-button">
        <button onClick={copyContent} className="add-another">
          {copyContentSuccess ? (
            "Copied"
          ) : (
            <img src={copy} alt={"copy"} style={{ height: "20px" }} />
          )}
        </button>
        <button onClick={downloadSig} className="add-another">
          <img src={download} alt={"doloaded"} style={{ height: "20px" }} />
        </button>
        <button onClick={saveSig} className="add-another">
          {copySaved ? "..." : "Save"}
        </button>
        <div className={showModal ? "modal-wrapper" : "modal-wrapper hide"}>
          <form>
            <fieldset
              className="close-wrapper"
              style={{ display: "flex", flexDirection: "row-reverse" }}
            >
              <img
                src={Close}
                style={{ width: "25px", cursor: "pointer" }}
                onClick={modalClose}
              />
              <p style={{ width: "100%", textAlign: "left" }}>
                Please ensure a unique name is used for your signature
              </p>
            </fieldset>
            <fieldset className="logo-upload">
              <input
                type="text"
                id="name-signature"
                placeholder="Name your signature"
                maxLength={17}
                onChange={signatureName}
              />
              <button
                className="add-another"
                onClick={saveSignatureWithName}
                style={{ width: "calc(100% - 15px)" }}
              >
                Save File
              </button>
              <button
                className="add-another"
                onClick={saveSignatureStyles}
                style={{ marginTop: "15px", width: "calc(100% - 15px)" }}
              >
                Save Styles
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TemplateOne;
