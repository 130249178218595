import React, {useRef, useState, useEffect} from 'react';
import FileUpload from './FileUpload.js';

function MoreBylines(props){
	
	return (
		<fieldset className="flex-color byline-extra">
            <label>Byline</label>
            <input 
              type="text" 
              id="color-byline-2"	
              placeholder="Enter byline"
							value={props.saved_value}
              onChange={props.byline_change}
              />
          	<div>
          		<input type="color" id="byline-color-additional" placeholder="pick a color"  onChange={props.byline_color_change} value={props.bylineColor}/>
          	</div>
      </fieldset>
	)
}


function MoreExtas(props){
	return (
		 <fieldset className="flex-color extra sub-extra">
	      	    <label>Extra</label>
	      	    <div className="field-50">
		      	    <div className="field">
			            <select 
			              type="text" 
			              id="name"
			              name="select-heading"
			              placeholder="Enter exta accent (P:, E:, W:)"
			              onChange={props.extraChangeHeading}
			              >
			              <option value="">Select option</option>
			              <option value="M:">Mobile</option>
									  <option value="P:">Phone</option>
									  <option value="E:">Email</option>
			              </select>
		             </div>
		             <div>
		          		<input type="color" id="color" placeholder="pick a color" name="heading-color" onInput={props.extraChangeHeadingColor}/>
		          	</div>
	          	</div>
	          	<div className="field-50">
	             <div className="field">
		            <input 
		              type="text" 
		              id="name"
		              name="extra-change"
		              placeholder="Enter exta accent (phone:, email:, website:)"
		              onChange={props.changeExtra}
		              />
	             </div>
	          	<div>
	          		<input type="color" id="color" placeholder="pick a color" name="extra-change-color" onChange={props.changeExtraColor}/>
	          	</div>
	          	</div>
	          	
	      </fieldset>
	)
}

function FormFields(props){
	const [template, setTemplate] = useState('');
	const [font, setFont] = useState('');
	const [heading, setHeading] = useState('');
	const [headingColor, setHeadingColor] = useState('');
	// bylines
	const [byline, setBylineInit] = useState('');
	const [bylineColor, setBylineColorChange] = useState('');
	// additional bylines
	const [addByline, setAddByline] = useState([]);
	const [bylineCount, setBylineCount] = useState();
	const [bylineCountInt, setBylineCountInt] = useState();
	const [bylineAdd, setByline] = useState('');
	const [bylimeLimit, setBylineLimit] = useState(false);
	const [addColor, setAddColor] = useState('');
	// logo
	const [logo, setLogo] = useState('');
	const [logoWidth, setWidthLogo] = useState('');
	// extras
	const [extraHeading, setExtraHeading] = useState('');
	const [extraHeadingColor, setExtraHeadingColor] = useState('');
	const [extraChange, setExtraChange] = useState('');
	const [extraChangeColor, setExtraChangeColor] = useState('');
	// add extra 
	const [addExtra, setAddExtra] = useState([]);
	// dynamic extras
  const [values, setValues] = useState([{ id: 0, heading: "", headingColor: "", extra: "", extraColor: "", heading_color_one_saved: "" }]);
	const [valuesSaved, setValuesSaved] = useState([{ id: 0, heading: "", headingColor: "", extra: "", extraColor: "", heading_color_one_saved: "" }]);

  const [addLimit, setAddLimit] = useState(0);
  const [displayLimit, setDisplayLimit] = useState(false);
   
  
     

   useEffect(() => {

   
  });

	// update heading
	const headingChange = (event) => {
		let target = event.currentTarget.value;
		setHeading(target);
	}
	const templateChange = (event) => {
		let target = event.currentTarget.value;
		setTemplate(target);
	}
	const fontChange = (event) => {
		let value = event.currentTarget.value;
		props.setFont(value);
	}
	// update heading color
	const headingColorChange = (event) => {
		setHeadingColor(event.currentTarget.value);
	}

	const bylineChange = (event) => {
		setBylineInit(event.currentTarget.value);
	}

	const bylineColorChange = (event) => {
		setBylineColorChange(event.currentTarget.value);
	}

	const additionalColorChange = (event) => {

		setAddColor(event.currentTarget.value);
	}


	const additionalBylineChange = (event) => {

		setByline(event.currentTarget.value);
	}
	// add more bylines
	const buttonAddByline = (event) => {
		event.preventDefault();
		setBylineCount(1);
		setBylineCount(props.setBylineCount(1));
		setBylineLimit(true);
		if(bylimeLimit == true){
			// nothing
		} else {
			setAddByline(addByline.concat(<MoreBylines key={addByline.length} byline_change={additionalBylineChange} byline_color_change={additionalColorChange} />));
		}
		
		
	 };


	const buttonAddExtra = (event) => {
		event.preventDefault();
		setAddLimit(addLimit + 1);
		
		if(addLimit == 3){
			setDisplayLimit(true);
		} else {
			setValues(arr => arr.concat([{ id: addLimit + 1, heading: "", headingColor: "", extra: "", extraColor: "" }]));
		}
		

	}

	const extraChangeHeading = (event) => {
		setExtraHeading(event.currentTarget.value);
	}

	const extraChangeHeadingColor = (event) => {
		setExtraHeadingColor(event.currentTarget.value);
	}

	const changeExtra = (event) => {
		setExtraChange(event.currentTarget.value);
	}

	const changeExtraColor = (event) => {
		setExtraChangeColor(event.currentTarget.value);
	}






	return (
		 <form>
			 {props.setHeading(heading)}
			 {props.setTemplate(template)}
			 {props.setHeadingColor(headingColor)}
			 {props.setBylineInit(byline)}
			 {props.setBylineColorChange(bylineColor)}
			 {props.setByline(bylineAdd)}
			 {props.setAddColor(addColor)}
			 {props.setLogo(logo)}
			 {props.setWidthLogo(logoWidth)}
			 {props.setExtraHeading(extraHeading)}
			 {props.setExtraHeadingColor(extraHeadingColor)}
			 {props.setExtraChange(extraChange)}
			 {props.setExtraChangeColor(extraChangeColor)}
			 {props.setValues(values)}
			
			 <fieldset>
			 	<h4>{props.setCompany} - {props.heading}</h4>
			 </fieldset>
			 <fieldset className="flex-color">
								
		            <label>Select your template</label>
		            <select 
		              type="text" 
		              id="name"
		              placeholder="Choose template"
		              onChange={templateChange}
		              >
		              	<option value="template-one">Template One</option>
  									<option value="template-two">Template Two</option>
		              </select>
		        
	          </fieldset>
	          <fieldset className="flex-color">
								
		            <label>Select your font</label>
		            <select 
		              type="text" 
		              id="name"
		              placeholder="Choose template"
		              onChange={fontChange}
		              >
		                <option value={props.setSavedFont ? props.setSavedFont : 'sans-serif'}>{props.setSavedFont ? props.setSavedFont : 'Select your font..'}</option>
		              	<option value="'Helvetica', sans-serif">'Helvetica', sans-serif</option>
  									<option value="'Arial', sans-serif">'Arial', sans-serif</option>
  									<option value="'Arial Black', sans-serif">'Arial Black', sans-serif</option>
  									<option value="'Verdana', sans-serif">'Verdana', sans-serif</option>
  									<option value="'Tahoma', sans-serif">'Tahoma', sans-serif</option>
  									<option value="'Trebuchet MS', sans-serif">'Trebuchet MS', sans-serif</option>
  									<option value="'Impact', sans-serif">'Impact', sans-serif</option>
  									<option value="'Gill Sans', sans-serif">'Gill Sans', sans-serif</option>
  									<option value="'Times New Roman', serif">'Times New Roman', serif</option>
  									<option value="'Georgia', serif">'Georgia', serif</option>
  									<option value="'Palatino', serif">'Palatino', serif</option>
  									<option value="'Baskerville', serif">'Baskerville', serif</option>
  									<option value="'Andalé Mono', monospace">'Andalé Mono', monospace</option>
  									<option value="'Courier', monospace">'Courier', monospace</option>
  									<option value="'Lucida', monospace">'Lucida', monospace</option>
  									<option value="'Monaco', monospace">'Monaco', monospace</option>
  									<option value="'Bradley Hand', cursive">'Bradley Hand', cursive</option>
  									<option value="'Brush Script MT', cursive">'Brush Script MT', cursive</option>
  									<option value="'Luminari', fantasy">'Luminari', fantasy</option>
  									<option value="'Comic Sans MS', cursive">'Comic Sans MS', cursive</option>
		              </select>
		        
	          </fieldset>
			  <fieldset className="flex-color">
								
		            <label>Main heading</label>
		            <input 
		              type="text" 
		              id="name"
		              placeholder="Enter heading e.g name"
									value={props.setSavedHeading ? props.setSavedHeading : heading}
		              onChange={headingChange}
		              />
		             <div>

	              	<input type="color" id="color-one" placeholder="pick a color" onChange={headingColorChange} value={headingColor ? headingColor : props.setSavedHeadingColor}/>
	              </div>
	          </fieldset>
	    
	          <fieldset className="flex-color">
	            <label>Byline</label>
	            <input 
	              type="text" 
	              id="name"
	              placeholder="Enter byline e.g. job title"
	              onChange={bylineChange}
								value={props.setSavedBylineInit ? props.setSavedBylineInit : byline}
	              />
	          	<div>
	          		<input type="color" id="color-byline-1" placeholder="pick a color" onChange={bylineColorChange} value={bylineColor ? bylineColor : props.setSavedBylineColorChange }/>
	          	</div>
	          {}
						{props.setSavedBylineCount == 1 ?
							<fieldset className="flex-color byline-extra">
				            <label>Byline</label>
				            <input 
				              type="text" 
				              id="color-byline-2"	
				              placeholder="Enter byline"
				              onChange={additionalBylineChange}
				              />
				          	<div>
				          		<input type="color" id="byline-color-additional" placeholder="pick a color"  onChange={additionalColorChange ? additionalColorChange : addColor} value={addColor ? addColor :props.setSavedAddColor}/>
				          	</div>
				      </fieldset>

						
						 : 
						 <span style={{width:'100%'}}>
								{addByline ? addByline : ""}
							<div className="byline-additional" style={{display:'flex',justifyContent:'start'}}>
								<button onClick={buttonAddByline} className="add-another">{bylimeLimit ? "Limit Reached!" : "Add Another+"}</button>
							</div>
							</span>

						}
							
							
	          
	      </fieldset>
	      
	      <FileUpload
	      setValue={setLogo}
	      setLogoWidth={setWidthLogo}
	      logo_width_saved={props.setLogoWidthSaved}
	      />
				
	       {
						      values.map((obj, i) => {

						      	// add saved data
						    	
										function handleChangeHeading(event) {
						          const value = event.currentTarget.value;
						          setValues(arr => arr.map(o => o === obj ? { ...o, heading: value} : o));
						          
						        }

						        function handleChangeHeadingColor(event) {
						          const value = event.currentTarget.value;
						          setValues(arr => arr.map(o => o === obj ? { ...o, headingColor: value} : o));


						        }
						        function handleExtra(event) {
						          const value = event.currentTarget.value;
						          setValues(arr => arr.map(o => o === obj ? { ...o, extra: value} : o));
						         
						        }
						        function handleExtraColor(event) {
						          const value = event.currentTarget.value;
						          setValues(arr => arr.map(o => o === obj ? { ...o, extraColor: value} : o));
						        }

						        function checkId(){
						        	let color = "";
						        	values.map((obj) => {
						        		if(obj.id == 0){
						        			color = props.setExtraHeadingColorOneSaved;
						        		}
						        	}) 
						        	return color;
						        }

						       

						       
						        
						        
						        return (
						        	<fieldset className="flex-color extra" key={i} test={props.setExtraHeadingColorOneSaved}>
					      	    <label>Extra</label>

					      	    <div className="field-50">
						      	    <div className="field">
							            <select 
							              type="text" 
							              id="name"
							              placeholder="Enter exta accent (P:, E:, W:)"
							              onChange={handleChangeHeading}
							              >
							              <option value="">Select option</option>
							              <option value="P:">Phone</option>
							              <option value="M:">Mobile</option>
													  <option value="W:">Website</option>
													  <option value="E:">Email</option>
							              </select>
						             </div>
						             <div>

						            	
											
						          		<input type="color" id="color" placeholder="pick a color" onChange={handleChangeHeadingColor} value={obj.headingColor || props.setExtraHeadingColorSaved ? obj.headingColor || props.setExtraHeadingColorSaved : checkId()}/>
						          	</div>
					          	</div>
					          	<div className="field-50">
					             <div className="field">
						            <input 
						              type="text" 
						              id="name"
						              placeholder="phone, mobile etc"
						              onChange={handleExtra}
						              />
					             </div>
					          	<div>
					          		<input type="color" id="color" placeholder="pick a color" onChange={handleExtraColor} value={obj.extraColor || props.setExtraValueHeadingTwoColor ? obj.extraColor || props.setExtraValueHeadingTwoColor : checkId()}/>
					          	</div>
					          	</div>
					          	
	          					</fieldset>
						         
						        );
						      })
						    }
	    				<div className="byline-additional">
	          		<button onClick={buttonAddExtra} className="add-another">{displayLimit ? "Limit Reached!" : "Add Another+"}</button>
	          	</div>
	     
	     
		</form>
	)

}

export default FormFields;