import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Login.scss';
import Notify from 'simple-notify';
import 'simple-notify/dist/simple-notify.min.css';
import PasswordStrengthBar from 'react-password-strength-bar';

const bcrypt = require('bcryptjs');



function pushNotify(title, message,status) {
  new Notify({
    status: status,
    title: title,
    text: message,
    effect: 'slide',
    speed: 300,
    customClass: 'notification-sig',
    customIcon: null,
    showIcon: true,
    showCloseButton: true,
    autoclose: true,
    autotimeout: 4000,
    gap: 20,
    distance: 20,
    type: 1,
    position: 'right bottom',
  });
}

function scrollToTop() {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
}

export default function Login(props) {
  const [form, setForm] = useState({
    username: '',
    password: '',
    password_check: '',
    company_name: '',
    first_name: '',
    last_name: '',
    email: '',
  });

  const [formLogin, setFormLogin] = useState({
    username: '',
    password: '',
  });

  const [register, setRegister] = useState(false);
  const [records, setRecords] = useState('');
  const [infoCheck, setInfoCheck] = useState(false);
  const [checkRegister, setCheckRegister] = useState(false);
  const [tokenInt, setTokenInt] = useState();

  useEffect(() => {
    const query = window.location.search;
    let check_token = localStorage.getItem('token');

    if(check_token){
      props.setToken(check_token);
    }
    if (query == '?registered') {
      setCheckRegister(true);
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });

    return;
  }, [records.length]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let current_username = formLogin.username;
    let current_password = formLogin.password;

    let user_id;
    let first_name;
    let company;

    let loginUser = {
      username: current_username,
      password: current_password
    }

    // 
                  
    await fetch(`/api/record/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginUser),
      })
      .then((response) => response.json())
      .then((data) => {
        console.log('data:' + data.password);
         bcrypt.compare(current_password, data.password, function(err, res) {
              // res === false
         
              if(res == true){
                props.setToken(data._id);
                localStorage.setItem('token', data._id);
                pushNotify('Welcome ' + data.first_name + '!','Login Successful','success');
              }
             
             
          });
      })
      .catch((error) => {
        pushNotify('Login error!','Please check credentials','error');
        return;
      });

  
    if (user_id) {
      localStorage.setItem('token', user_id);
    }

  };

  function SubmitGoogleSheetSoon({ username, company_name, first_name, last_name, email, date_time}) {
   
  // Show loader if needed
          const utc = new Date().toLocaleString();
          const data = {
              "username": username,
              "company_name": company_name,
              "first_name": first_name,
              "last_name": last_name,
              "email": email,
              "date_time": utc,
          };


          const sheeturl = "https://script.google.com/macros/s/AKfycbxYxUT9HPHiRQn4h8Yykhu1FTiS4vwBrhVgA6fzkAa3QWfeAATSQ2CCQxmXDw17af1d/exec";

          // Google Sheets Request
          fetch(`${sheeturl}?${new URLSearchParams(data)}`, {
              method: "GET",
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
              },
          })
          .then(response => response.json())
          .then(data => {
              console.log(data);
            
          })
          .catch(error => {
              console.error('Error:', error);
          });
  }


 async function handleSubmitRegister(e) {
  e.preventDefault();

  if (form.password !== form.password_check) {
    pushNotify('Passwords don\'t match!', 'Please re-enter your passwords', 'error');
  } else {
    // When a post request is sent to the create url, we'll add a new record to the database.
    const newUser = { ...form };
    await fetch(`/api/record/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newUser),
    }).catch((error) => {
      window.alert(error);
      return;
    });

    // Call SubmitGoogleSheetSoon with the correct parameters
    SubmitGoogleSheetSoon({ 
      username: form.username, 
      company_name: form.company_name, 
      first_name: form.first_name, 
      last_name: form.last_name, 
      email: form.email,
      date_time: new Date().toLocaleString(), // Add date_time here if needed
    });

    setRegister(false);
    setForm({ username: '', company: '', first_name: '', last_name: '', email: '', password: '', password_check: '' });
    //window.location.href = '/?registered';
  }
}


  const RegisterToggle = (e) => {
    e.preventDefault();
    setRegister(true);
    scrollToTop();
  };
  const LoginToggle = (e) => {
    e.preventDefault();
    setRegister(false);
    scrollToTop();
  };

  // These methods will update the state properties.
  function updateForm(value) {
    return setForm((prev) => {
      return { ...prev, ...value };
    });
  }

  const checkValidate = (e) => {
    e.preventDefault();
    let confirmed_pass = e.currentTarget.value;
    if (confirmed_pass == form.password) {
      // nothing
    } else {
      pushNotify('Password don\'t match!', 'Please re-enter your passwords','error');
    }
  };

  return (
    <div className="login-wrapper">
      {/* REGISTER FORM */}
      <form onSubmit={handleSubmitRegister} className={register ? 'register-form show' : 'register-form'}>
        <fieldset>
          <h5 className="form-login-heading">mesig.io</h5>
          <h4>Please Register</h4>
        </fieldset>
        <fieldset className="flex">
          <label>
            <p>Username</p>
            <input
              type="text"
              placeholder="Enter username"
              onChange={(e) => {
                const value = e.target.value;
                setForm((form) => ({ ...form, username: value }));
              }}
            />
          </label>
          <label>
            <p>Company Name</p>
            <input
              type="text"
              placeholder="Enter company name"
              onChange={(e) => {
                const value = e.target.value;
                setForm((form) => ({ ...form, company_name: value }));
              }}
            />
          </label>
        </fieldset>
        <fieldset className="flex">
          <label>
            <p>First Name</p>
            <input
              type="text"
              placeholder="Enter first name"
              onChange={(e) => {
                const value = e.target.value;
                setForm((form) => ({ ...form, first_name: value }));
              }}
            />
          </label>
          <label>
            <p>Last Name</p>
            <input
              type="text"
              placeholder="Enter last name"
              onChange={(e) => {
                const value = e.target.value;
                setForm((form) => ({ ...form, last_name: value }));
              }}
            />
          </label>
        </fieldset>
        <fieldset className="flex">
          <label>
            <p>Email</p>
            <input
              type="email"
              placeholder="Enter email"
              onChange={(e) => {
                const value = e.target.value;
                setForm((form) => ({ ...form, email: value }));
              }}
            />
          </label>

          <label>
            <p>Password</p>
            <input
              type="password"
              placeholder="Enter password"
              onChange={(e) => {
                const value = e.target.value;
                setForm((form) => ({ ...form, password: value }));
              }}
            />
          </label>
        </fieldset>
        <label>
          <p>Confirm Password</p>
          <input
            type="password"
            placeholder="Enter password check"
            onChange={(e) => {
              const value = e.target.value;
              setForm((form) => ({ ...form, password_check: value }));
            }}
          />
          <PasswordStrengthBar password={form.password_check || form.password} />
        </label>
        <div>
          <button type="submit" className="add-another">
            Register
          </button>
        </div>
      </form>
      {/* LOGIN FORM */}
      <form onSubmit={handleSubmit} className={register ? 'login-form hide' : 'login-form'}>
        <fieldset>
          <h5 className="form-login-heading">mesig.io</h5>
          <h4>Please Log In</h4>
          {checkRegister ? <p className="error">Thank you for registering - please login below</p> : ''}
          {infoCheck ? <p className="error">No username or password found!</p> : ''}
        </fieldset>
        <label>
          <p>Username</p>
          <input
            type="text"
            placeholder="Enter username"
            onChange={(e) => {
              const value = e.target.value;
              setFormLogin((form) => ({ ...form, username: value }));
            }}
          />
        </label>
        <label>
          <p>Password</p>
          <input
            type="password"
            placeholder="Enter password"
            onChange={(e) => {
              const value = e.target.value;
              setFormLogin((form) => ({ ...form, password: value }));
            }}
          />
        </label>
        <div>
          <button type="submit" className="add-another">
            Login
          </button>
        </div>
      </form>
      <p style={{ fontSize: '14px' }} className={register ? 'hide' : 'show'}>
        Don't have an account? register <button onClick={RegisterToggle}>here</button>.
      </p>
      <p style={{ fontSize: '14px' }} className={register ? 'show' : 'hide'}>
        Back to login <button onClick={LoginToggle}>here</button>.
      </p>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
  setFirstName: '',
  setCompany: '',
};
