import React, {useRef, useState, useEffect} from 'react';

function Loader(props){
	return (
		<div className={props.isActive + " loader-wrapper"}>
	 		<span class="loader"></span>
	 	</div>
	)
}

export default Loader;